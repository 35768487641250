.adyen-checkout-ctp__card {
  width: 100%;
  height: var(--adyen-sdk-spacer-110, 40px);
  margin: 0;
  background-color: white;
  border: none;
  border-radius: var(--adyen-sdk-spacer-020, 4px);
  margin-bottom: var(--adyen-sdk-spacer-080, 20px);
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) #999595;
  cursor: pointer;
}

.adyen-checkout-ctp__empty-cards {
  border-radius: var(--adyen-sdk-spacer-030, 6px);
  background-color: var(--adyen-sdk-color-background-secondary, #f7f7f8);
  color: var(--adyen-sdk-color-label-tertiary, #8d95a3);
  height: var(--adyen-sdk-spacer-110, 40px);
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  padding: var(--adyen-sdk-spacer-060, 12px) var(--adyen-sdk-spacer-070, 16px);
  margin-bottom: var(--adyen-sdk-spacer-090, 24px);
}

.adyen-checkout__iframe--ctpIframe {
  pointer-events: auto;
}

.adyen-checkout__iframe--ctpIframe-hidden {
  display: none;
}