.adyen-checkout-paynow__instructions {
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  text-align: center;
}

.adyen-checkout-paynow__instructions > p {
  margin-bottom: 0;
}