.adyen-checkout__button-group {
  background: transparent;
  display: flex;
  justify-content: space-between;
}
.adyen-checkout__button-group .adyen-checkout__button {
  background: transparent;
  border: 0;
  box-shadow: inset 0 0 0 1px var(--adyen-sdk-color-outline-primary, #dbdee2);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  font-weight: normal;
  line-height: var(--adyen-sdk-spacer-110, 40px);
  margin-right: var(--adyen-sdk-spacer-040, 8px);
  height: var(--adyen-sdk-spacer-110, 40px);
  padding: 0;
  text-align: center;
}
.adyen-checkout__button-group .adyen-checkout__button:last-child {
  margin-right: 0;
}
.adyen-checkout__button-group .adyen-checkout__button:hover {
  background: transparent;
  box-shadow: inset 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-primary-hover, #c9cdd3);
}
.adyen-checkout__button-group .adyen-checkout__button:active {
  background: var(--adyen-sdk-color-background-secondary, #f7f7f8);
  box-shadow: inset 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}
.adyen-checkout__button-group .adyen-checkout__button--disabled, .adyen-checkout__button-group .adyen-checkout__button--disabled:hover {
  cursor: not-allowed;
  opacity: 0.4;
  user-select: none;
}
.adyen-checkout__button-group .adyen-checkout__button--selected, .adyen-checkout__button-group .adyen-checkout__button--selected:hover, .adyen-checkout__button-group .adyen-checkout__button--selected:active, .adyen-checkout__button-group .adyen-checkout__button--selected:active:hover {
  background: var(--adyen-sdk-color-background-secondary, #f7f7f8);
  box-shadow: inset 0 0 0 1px var(--adyen-sdk-color-label-primary, #00112c);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
  height: var(--adyen-sdk-spacer-110, 40px);
  transition: none;
}
.adyen-checkout__button-group .adyen-checkout__button .adyen-checkout__button-group__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}