.adyen-checkout__field--separateDeliveryAddress .adyen-checkout__input-wrapper {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.adyen-checkout__field--separateDeliveryAddress .adyen-checkout__input-wrapper:active, .adyen-checkout__field--separateDeliveryAddress .adyen-checkout__input-wrapper:active:hover, .adyen-checkout__field--separateDeliveryAddress .adyen-checkout__input-wrapper:focus-within, .adyen-checkout__field--separateDeliveryAddress .adyen-checkout__input-wrapper:focus-within:hover {
  box-shadow: none;
}

.adyen-checkout__open-invoice .adyen-checkout__fieldset--personalDetails {
  padding-bottom: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__open-invoice .adyen-checkout__fieldset--billingAddress {
  padding-bottom: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__open-invoice .adyen-checkout__fieldset--deliveryAddress {
  margin-top: var(--adyen-sdk-spacer-090, 24px);
  padding-bottom: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__open-invoice .adyen-checkout__input--separateDeliveryAddress {
  margin-bottom: 0;
}

.adyen-checkout__open-invoice .adyen-checkout__field--consentCheckbox {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
}