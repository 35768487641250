.adyen-checkout-upi-app-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout-upi-app-list--loading {
  user-select: none;
  pointer-events: none;
}