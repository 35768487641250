.adyen-checkout__button {
  background: var(--adyen-sdk-color-background-always-dark, #00112c);
  border: 0;
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  color: var(--adyen-sdk-color-label-on-color, #ffffff);
  cursor: pointer;
  font-size: var(--adyen-sdk-text-title-font-size, 16px);
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
  height: var(--adyen-sdk-spacer-120, 48px);
  margin: 0;
  padding: var(--adyen-sdk-spacer-060, 12px) var(--adyen-sdk-spacer-000, 0px);
  text-decoration: none;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 100%;
}
.adyen-checkout__button:focus {
  box-shadow: 0 0 0 1px var(--adyen-sdk-color-outline-tertiary, #8d95a3);
  outline: 0;
}
.adyen-checkout__button:hover {
  background: var(--adyen-sdk-color-background-inverse-primary-hover, #5c687c);
  box-shadow: 0 0, 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-spacer-020, 4px) -1px rgba(0, 0, 0, 0.2), 0 var(--adyen-sdk-spacer-020, 4px) 5px 0 rgba(0, 0, 0, 0.14);
}
.adyen-checkout__button:active {
  background: var(--adyen-sdk-color-background-always-dark-active, #8d95a3);
}
.adyen-checkout__button:hover:focus {
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-tertiary, #8d95a3), 0 3px var(--adyen-sdk-spacer-020, 4px) rgba(0, 15, 45, 0.2);
}
.adyen-checkout__button:disabled, .adyen-checkout__button:disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
  user-select: all;
}
.adyen-checkout__button.adyen-checkout__button--loading {
  background: var(--adyen-sdk-color-label-disabled, #8d95a3);
  box-shadow: none;
  pointer-events: none;
  user-select: none;
}
.adyen-checkout__button.adyen-checkout__button--pay {
  margin-top: var(--adyen-sdk-spacer-090, 24px);
  display: flex;
  justify-content: center;
}
.adyen-checkout__button.adyen-checkout__button--pay:disabled {
  opacity: 0.4;
}
.adyen-checkout__button.adyen-checkout__button--standalone {
  margin-top: 0;
}
.adyen-checkout__button.adyen-checkout__button--inline {
  display: block;
  width: auto;
  height: auto;
  padding: var(--adyen-sdk-spacer-050, 10px) var(--adyen-sdk-spacer-040, 8px);
  font-size: 0.81em;
}
.adyen-checkout__button.adyen-checkout__button--ghost {
  background: none;
  border: 0;
  color: var(--adyen-sdk-color-label-primary, #00112c);
}
.adyen-checkout__button.adyen-checkout__button--ghost .adyen-checkout__spinner::before {
  border-color: var(--adyen-sdk-color-background-inverse-primary, #00112c);
  border-top-color: transparent;
  opacity: 0.1;
}
.adyen-checkout__button.adyen-checkout__button--ghost .adyen-checkout__spinner::after {
  border-color: transparent;
  border-top-color: var(--adyen-sdk-color-outline-primary-active, #00112c);
}
.adyen-checkout__button.adyen-checkout__button--ghost:hover {
  background: var(--adyen-sdk-color-background-secondary-hover, #eeeff1);
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--ghost:active {
  background: var(--adyen-sdk-color-background-secondary-active, #e3e5e9);
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--secondary {
  padding: var(--adyen-sdk-spacer-050, 10px) var(--adyen-sdk-spacer-060, 12px);
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-label-primary, #00112c);
  color: var(--adyen-sdk-color-label-primary, #00112c);
}
.adyen-checkout__button.adyen-checkout__button--secondary .adyen-checkout__spinner::before {
  border-color: var(--adyen-sdk-color-background-inverse-primary, #00112c);
  border-top-color: transparent;
  opacity: 0.1;
}
.adyen-checkout__button.adyen-checkout__button--secondary .adyen-checkout__spinner::after {
  border-color: transparent;
  border-top-color: var(--adyen-sdk-color-outline-primary-active, #00112c);
}
.adyen-checkout__button.adyen-checkout__button--secondary:hover {
  background: var(--adyen-sdk-color-background-secondary, #f7f7f8);
  box-shadow: 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-spacer-020, 4px) rgba(27, 42, 60, 0.2), 0 var(--adyen-sdk-spacer-020, 4px) 5px rgba(27, 42, 60, 0.14);
}
.adyen-checkout__button.adyen-checkout__button--secondary:active, .adyen-checkout__button.adyen-checkout__button--secondary:active:hover {
  background: var(--adyen-sdk-color-background-secondary, #f7f7f8);
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--secondary:focus {
  box-shadow: 0 0 0 0.5px var(--adyen-sdk-color-outline-tertiary, #8d95a3);
  outline: 0;
}
.adyen-checkout__button.adyen-checkout__button--secondary:disabled, .adyen-checkout__button.adyen-checkout__button--secondary:disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
  border-color: var(--adyen-sdk-color-outline-disabled, #dbdee2);
  background-color: var(--adyen-sdk-color-background-secondary, #f7f7f8);
  opacity: 0.5;
  user-select: all;
}
.adyen-checkout__button.adyen-checkout__button--action {
  padding: var(--adyen-sdk-spacer-050, 10px) var(--adyen-sdk-spacer-060, 12px);
  background: var(--adyen-sdk-color-background-always-dark, #00112c);
  border: var(--adyen-sdk-border-width-s, 1px) solid transparent;
  color: var(--adyen-sdk-color-label-on-color, #ffffff);
}
.adyen-checkout__button.adyen-checkout__button--action:hover {
  background: var(--adyen-sdk-color-background-inverse-primary-hover, #5c687c);
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--action:active, .adyen-checkout__button.adyen-checkout__button--action:active:hover {
  background: var(--adyen-sdk-color-background-always-dark-active, #8d95a3);
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--link {
  background: transparent;
  border: var(--adyen-sdk-border-width-s, 1px) solid transparent;
  color: var(--adyen-sdk-color-label-highlight, #0070f5);
  text-decoration: none;
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  border-radius: var(--adyen-sdk-border-radius-s, 4px);
  padding: var(--adyen-sdk-spacer-010, 2px);
}
.adyen-checkout__button.adyen-checkout__button--link:hover {
  background: transparent;
  text-decoration: underline;
  box-shadow: none;
}
.adyen-checkout__button.adyen-checkout__button--completed, .adyen-checkout__button.adyen-checkout__button--completed:hover, .adyen-checkout__button.adyen-checkout__button--completed:active, .adyen-checkout__button.adyen-checkout__button--completed:active:hover {
  background: var(--adyen-sdk-color-label-success, #07893c);
  color: var(--adyen-sdk-color-label-on-color, #ffffff);
}
.adyen-checkout__button.adyen-checkout__button--completed .adyen-checkout__button__icon {
  filter: brightness(0) invert(1);
}
.adyen-checkout__button__content {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.adyen-checkout__button__icon {
  margin-right: var(--adyen-sdk-spacer-040, 8px);
}
[dir=rtl] .adyen-checkout__button__icon {
  margin-right: 0;
  margin-left: var(--adyen-sdk-spacer-040, 8px);
}
.adyen-checkout__button__text {
  display: block;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.adyen-checkout__button__text--sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  position: absolute;
}
.adyen-checkout__button .adyen-checkout__spinner::before {
  border-color: var(--adyen-sdk-color-label-on-color, #ffffff);
  border-top-color: transparent;
  opacity: 0.1;
}
.adyen-checkout__button .adyen-checkout__spinner::after {
  border-color: transparent;
  border-top-color: var(--adyen-sdk-color-label-on-color, #ffffff);
}