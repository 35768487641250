.adyen-checkout-upi-app-item {
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  display: flex;
  gap: var(--adyen-sdk-spacer-070, 16px);
  flex-direction: column;
  padding: var(--adyen-sdk-spacer-050, 10px) var(--adyen-sdk-spacer-070, 16px);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-primary, #dbdee2);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  cursor: pointer;
  width: 100%;
  transition: background 0.15s ease, border-color 0.15s ease, color 0.15s ease, box-shadow 0.15s ease, border-radius 0.15s ease;
}
.adyen-checkout-upi-app-item__label {
  cursor: pointer;
}
.adyen-checkout-upi-app-item:hover:not(.adyen-checkout-upi-app-item--selected) {
  border-color: var(--adyen-sdk-color-outline-primary-hover, #c9cdd3);
}
.adyen-checkout-upi-app-item--selected:not(.adyen-checkout-upi-app-item:last-child) {
  border-color: var(--adyen-sdk-color-outline-primary-active, #00112c);
  box-shadow: 0 0 0 0.5px var(--adyen-sdk-color-outline-primary-active, #00112c);
}
.adyen-checkout-upi-app-item--selected {
  cursor: default;
  border-color: var(--adyen-sdk-color-outline-secondary, #c9cdd3);
}
.adyen-checkout-upi-app-item--selected .adyen-checkout-expand-button--upi-app-item {
  cursor: default;
}
.adyen-checkout-upi-app-item--selected .adyen-checkout-expand-button--upi-app-item > label {
  cursor: default;
}
.adyen-checkout-upi-app-item .adyen-checkout-expand-button--upi-app-item {
  margin-right: 0;
}