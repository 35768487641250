.adyen-checkout__order-payment-methods-list {
  list-style: none;
  margin: 0 auto var(--adyen-sdk-spacer-070, 16px);
  padding: 0;
}

.adyen-checkout__order-payment-method {
  position: relative;
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-tertiary, #8d95a3);
  margin-top: -1px;
  width: 100%;
}

.adyen-checkout__order-payment-method:first-child {
  border-top-left-radius: var(--adyen-sdk-border-radius-m, 8px);
  border-top-right-radius: var(--adyen-sdk-border-radius-m, 8px);
}

.adyen-checkout__order-payment-method:last-child {
  border-bottom-left-radius: var(--adyen-sdk-border-radius-m, 8px);
  border-bottom-right-radius: var(--adyen-sdk-border-radius-m, 8px);
}

.adyen-checkout__order-payment-method__header {
  align-items: center;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
  font-size: var(--adyen-sdk-text-title-font-size, 16px);
  padding: var(--adyen-sdk-spacer-070, 16px);
  position: relative;
  transition: background 0.1s ease-out;
  width: 100%;
}
.adyen-checkout__order-payment-method__header .adyen-checkout__payment-method__header__title {
  padding: var(--adyen-sdk-spacer-000, 0px);
}

.adyen-checkout__order-payment-method__details {
  padding: 0 var(--adyen-sdk-spacer-070, 16px) var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout__order-payment-method__deducted-amount {
  display: flex;
  justify-content: space-between;
  line-height: var(--adyen-sdk-text-subtitle-font-size, 16px);
  font-size: var(--adyen-sdk-text-subtitle-font-size, 16px);
}

.adyen-checkout__order-payment-method__deducted-amount__label {
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
}

.adyen-checkout__order-payment-method__deducted-amount__value {
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
}

.adyen-checkout__order-remaining-amount {
  display: block;
  width: 100%;
  padding: var(--adyen-sdk-spacer-040, 8px) var(--adyen-sdk-spacer-070, 16px);
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
}
.adyen-checkout__order-remaining-amount strong {
  font-weight: bold;
}