.adyen_checkout-ctp__brand-wrapper {
  display: flex;
  align-items: center;
  height: 18px;
}
.adyen_checkout-ctp__brand-logo {
  width: var(--adyen-sdk-spacer-090, 24px);
  margin-right: var(--adyen-sdk-spacer-030, 6px);
}
.adyen_checkout-ctp__brand-pipe {
  height: 15px;
  margin-right: var(--adyen-sdk-spacer-030, 6px);
}
.adyen_checkout-ctp__brand-scheme {
  object-fit: none;
  margin-right: var(--adyen-sdk-spacer-030, 6px);
}
.adyen_checkout-ctp__brand-scheme-mc {
  width: 27px;
}
.adyen_checkout-ctp__brand-scheme-visa {
  width: 35px;
}