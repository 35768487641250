.adyen-checkout__adyen-giving .adyen-checkout__status__icon {
  display: block;
  margin: var(--adyen-sdk-spacer-130, 56px) auto var(--adyen-sdk-spacer-100, 32px);
}
.adyen-checkout__adyen-giving .adyen-checkout__status__text {
  color: var(--adyen-sdk-color-label-primary, #00112c);
  margin-bottom: var(--adyen-sdk-spacer-130, 56px);
  text-align: center;
}
.adyen-checkout__adyen-giving .adyen-checkout-disclaimer__label {
  margin-top: 0;
  color: var(--adyen-sdk-color-label-primary, #00112c);
}

.adyen-checkout__campaign {
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  background: var(--adyen-sdk-color-label-primary, #00112c);
  height: 227px;
  overflow: hidden;
  position: relative;
}

.adyen-checkout__campaign-link:hover .adyen-checkout__campaign-description {
  text-decoration: underline;
}

.adyen-checkout__campaign-container {
  height: 100%;
}

.adyen-checkout__campaign-logo {
  border: var(--adyen-sdk-spacer-010, 2px) solid rgba(255, 255, 255, 0.4);
  border-radius: var(--adyen-sdk-border-radius-s, 4px);
  display: block;
  height: var(--adyen-sdk-spacer-120, 48px);
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
  overflow: hidden;
  width: var(--adyen-sdk-spacer-120, 48px);
}

.adyen-checkout__campaign-background-image {
  background-color: var(--adyen-sdk-color-label-primary, #00112c);
  background-position: center;
  background-size: cover;
  height: 100%;
}

.adyen-checkout__campaign-link .adyen-checkout__campaign-background-image::before {
  background: inherit;
  content: "";
  height: 100%;
  position: absolute;
  transition: transform 0.6s ease-out;
  width: 100%;
}
.adyen-checkout__campaign-link .adyen-checkout__campaign-background-image:hover::before {
  transform: scale(1.1);
}
.adyen-checkout__campaign-link .adyen-checkout__campaign-content {
  pointer-events: none;
}

.adyen-checkout__campaign-content {
  bottom: 0;
  padding: var(--adyen-sdk-spacer-070, 16px);
  position: absolute;
  z-index: 2;
}

.adyen-checkout__campaign-title,
.adyen-checkout__campaign-description {
  color: var(--adyen-sdk-color-label-on-color, #ffffff);
  font-weight: normal;
  margin: 0;
}

.adyen-checkout__campaign-title {
  font-size: var(--adyen-sdk-text-title-font-size, 16px);
  font-weight: var(--adyen-sdk-text-title-font-weight, 600);
  line-height: var(--adyen-sdk-text-title-line-height, 26px);
  margin-bottom: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__campaign-cause {
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  font-weight: var(--adyen-sdk-text-caption-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  color: var(--adyen-sdk-color-label-on-color, #ffffff);
  font-weight: var(--adyen-sdk-text-caption-stronger-font-weight, 500);
}

.adyen-checkout__campaign-description {
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  font-weight: var(--adyen-sdk-text-caption-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
}

.adyen-checkout-roundup-description {
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  font-weight: var(--adyen-sdk-text-caption-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  display: inline-block;
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  padding-left: 0;
}

.adyen-checkout__adyen-giving-actions {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
  display: flex;
  flex-direction: column;
  gap: var(--adyen-sdk-spacer-070, 16px);
  text-align: center;
}

.adyen-checkout__button.adyen-checkout__button--donate {
  margin: var(--adyen-sdk-spacer-000, 0px);
}
.adyen-checkout__button.adyen-checkout__button--decline {
  display: block;
  margin: auto;
}