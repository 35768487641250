.adyen-checkout-checkmark {
  border-bottom: 1.5px solid var(--adyen-sdk-color-label-primary, #00112c);
  border-right: 1.5px solid var(--adyen-sdk-color-label-primary, #00112c);
  transform: rotate(45deg);
  height: var(--adyen-sdk-spacer-060, 12px);
  width: var(--adyen-sdk-spacer-030, 6px);
}

.adyen-checkout__segmented-control {
  display: flex;
  justify-content: space-between;
  gap: var(--adyen-sdk-spacer-020, 4px);
  padding: var(--adyen-sdk-spacer-030, 6px);
  background: var(--adyen-sdk-color-background-tertiary, #eeeff1);
  border-radius: var(--adyen-sdk-border-radius-l, 12px);
}
.adyen-checkout__segmented-control--disabled {
  pointer-events: none;
}
.adyen-checkout__segmented-control--disabled > .adyen-checkout__segmented-control-segment {
  color: var(--adyen-sdk-color-label-disabled, #8d95a3);
}
.adyen-checkout__segmented-control--disabled > .adyen-checkout__segmented-control-segment--selected .adyen-checkout-checkmark {
  border-bottom: 1.5px solid var(--adyen-sdk-color-label-disabled, #8d95a3);
  border-right: 1.5px solid var(--adyen-sdk-color-label-disabled, #8d95a3);
}

.adyen-checkout__segmented-control-segment {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--adyen-sdk-spacer-050, 10px);
  flex-grow: 1;
  cursor: pointer;
  width: 100%;
  height: var(--adyen-sdk-spacer-110, 40px);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  text-align: center;
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  border: 0;
  background: transparent;
  transition: background 0.3s ease-out;
}
.adyen-checkout__segmented-control-segment:not(.adyen-checkout__segmented-control-segment--selected):hover {
  background-color: var(--adyen-sdk-color-background-secondary, #f7f7f8);
}
.adyen-checkout__segmented-control-segment:active {
  background-color: var(--adyen-sdk-color-background-secondary, #f7f7f8);
}
.adyen-checkout__segmented-control-segment--selected {
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  box-shadow: var(--adyen-sdk-shadow-low, 0px 2px 4px rgba(0, 17, 44, 0.04), 0px 1px 2px rgba(0, 17, 44, 0.02));
}