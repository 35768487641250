.adyen-checkout__fieldset--personalDetails .adyen-checkout__field--gender .adyen-checkout__radio_group {
  display: flex;
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group {
  display: flex;
  margin: var(--adyen-sdk-spacer-040, 8px) 0;
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group__input-wrapper {
  margin-right: var(--adyen-sdk-spacer-080, 20px);
}
.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group__input-wrapper:last-child {
  margin: 0;
}

.adyen-checkout__field--gender .adyen-checkout__input-wrapper {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.adyen-checkout__field--gender .adyen-checkout__input-wrapper:active, .adyen-checkout__field--gender .adyen-checkout__input-wrapper:active:hover, .adyen-checkout__field--gender .adyen-checkout__input-wrapper:focus-within, .adyen-checkout__field--gender .adyen-checkout__input-wrapper:focus-within:hover {
  box-shadow: none;
}