.adyen-checkout__paypal__buttons {
  position: relative;
  z-index: 0;
}
.adyen-checkout__paypal__button {
  display: flex;
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout__paypal__button:empty {
  display: none;
}
.adyen-checkout__paypal__status--pending {
  margin: var(--adyen-sdk-spacer-070, 16px) 0;
}
.adyen-checkout__paypal__status--processing {
  align-items: center;
  display: flex;
  font-size: 13px;
  justify-content: center;
  padding: var(--adyen-sdk-spacer-090, 24px) 0;
}

.adyen-checkout__paypal-processing .adyen-checkout__paypal__button {
  display: none;
}

.adyen-checkout__payment-method .adyen-checkout__paypal__status--pending {
  margin: var(--adyen-sdk-spacer-070, 16px) 0 38px;
}
.adyen-checkout__payment-method .adyen-checkout__paypal__status--processing {
  padding: var(--adyen-sdk-spacer-080, 20px) 0 65px;
}