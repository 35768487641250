/* Shared css variables and styles are imported once here at the root level for all UI components. */
.adyen-checkout-link {
  --text-decoration: none;
  color: var(--adyen-sdk-color-label-highlight, #0070f5);
  text-decoration: var(--text-decoration);
}
.adyen-checkout-link:focus-visible {
  outline: none;
}
.adyen-checkout-link:hover, .adyen-checkout-link:active {
  --text-decoration: underline;
}
.adyen-checkout-link:focus-visible {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px var(--adyen-sdk-focus-ring-color, rgba(0, 112, 245, 0.8));
  transition: 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  border-radius: var(--adyen-sdk-border-radius-xs, 2px);
}

[class^=adyen-checkout], [class^=adyen-checkout]::before, [class^=adyen-checkout]::after {
  box-sizing: border-box;
}
[class^=adyen-checkout] *,
[class^=adyen-checkout] *::before,
[class^=adyen-checkout] *::after {
  box-sizing: border-box;
}