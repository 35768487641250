.adyen-checkout__address-search {
  position: relative;
}
.adyen-checkout__address-search .adyen-checkout__dropdown__button::after {
  position: absolute;
  content: "";
  left: var(--adyen-sdk-spacer-060, 12px);
  height: var(--adyen-sdk-spacer-070, 16px);
  width: var(--adyen-sdk-spacer-070, 16px);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23687282' d='M6.5 11.9a4.5 4.5 0 0 0 2.6-.83l2.77 2.74c.13.13.3.19.48.19.38 0 .65-.29.65-.66a.63.63 0 0 0-.19-.46l-2.75-2.73a4.4 4.4 0 0 0 .92-2.7 4.48 4.48 0 0 0-8.98 0 4.48 4.48 0 0 0 4.5 4.45Zm0-.96a3.53 3.53 0 0 1-3.53-3.49 3.52 3.52 0 0 1 7.04 0c0 1.9-1.59 3.49-3.52 3.49Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}
.adyen-checkout__address-search .adyen-checkout__dropdown__button--active::after {
  transform: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%2300112C' d='M6.5 11.9a4.5 4.5 0 0 0 2.6-.83l2.77 2.74c.13.13.3.19.48.19.38 0 .65-.29.65-.66a.63.63 0 0 0-.19-.46l-2.75-2.73a4.4 4.4 0 0 0 .92-2.7 4.48 4.48 0 0 0-8.98 0 4.48 4.48 0 0 0 4.5 4.45Zm0-.96a3.53 3.53 0 0 1-3.53-3.49 3.52 3.52 0 0 1 7.04 0c0 1.9-1.59 3.49-3.52 3.49Z'/%3E%3C/svg%3E%0A");
}
.adyen-checkout__address-search .adyen-checkout__filter-input {
  position: relative;
  padding-left: calc(var(--adyen-sdk-spacer-040, 8px) + var(--adyen-sdk-spacer-070, 16px));
}

.adyen-checkout__address-search__manual-add {
  position: absolute;
  top: 0;
  right: 0;
}
.adyen-checkout__address-search__manual-add .adyen-checkout__address-search__manual-add__button {
  border: 0;
  padding: 0;
}