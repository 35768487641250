.adyen-checkout__installments .adyen-checkout__input-wrapper {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.adyen-checkout__installments .adyen-checkout__input-wrapper:active, .adyen-checkout__installments .adyen-checkout__input-wrapper:active:hover, .adyen-checkout__installments .adyen-checkout__input-wrapper:focus-within, .adyen-checkout__installments .adyen-checkout__input-wrapper:focus-within:hover {
  box-shadow: none;
}
.adyen-checkout__installments .adyen-checkout__fieldset--revolving-plan {
  margin-top: -5px;
}