.adyen-checkout__spinner__wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adyen-checkout__spinner__wrapper--inline {
  height: auto;
  display: inline-block;
  margin-right: var(--adyen-sdk-spacer-040, 8px);
}
[dir=rtl] .adyen-checkout__spinner__wrapper--inline {
  margin-right: 0;
  margin-left: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__spinner {
  height: var(--adyen-sdk-spacer-090, 24px);
  width: var(--adyen-sdk-spacer-090, 24px);
  position: relative;
}
.adyen-checkout__spinner::before, .adyen-checkout__spinner::after {
  animation: rotate 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  border: var(--adyen-sdk-border-width-l, 3px) solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.adyen-checkout__spinner::before {
  border-color: var(--adyen-sdk-color-background-inverse-primary, #00112c);
  border-top-color: transparent;
  opacity: 0.1;
}
.adyen-checkout__spinner::after {
  border-color: transparent;
  border-top-color: var(--adyen-sdk-color-outline-primary-active, #00112c);
}
.adyen-checkout__spinner--small {
  height: var(--adyen-sdk-spacer-070, 16px);
  width: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout__spinner--small::before, .adyen-checkout__spinner--small::after {
  border-width: var(--adyen-sdk-spacer-010, 2px);
}
.adyen-checkout__spinner--medium {
  height: 28px;
  width: 28px;
}
.adyen-checkout__spinner--large {
  height: var(--adyen-sdk-spacer-120, 48px);
  width: var(--adyen-sdk-spacer-120, 48px);
}
.adyen-checkout__spinner--large::before, .adyen-checkout__spinner--large::after {
  border-width: var(--adyen-sdk-spacer-020, 4px);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}