.adyen-checkout__issuer-button {
  background-color: var(--adyen-sdk-color-background-primary, #ffffff);
  box-shadow: inset 0 0 0 1px var(--adyen-sdk-color-outline-primary, #dbdee2);
  border: none;
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  padding: var(--adyen-sdk-spacer-000, 0px) var(--adyen-sdk-spacer-060, 12px);
  cursor: pointer;
  flex-basis: 47%;
  flex-grow: 2;
  height: var(--adyen-sdk-spacer-110, 40px);
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out;
}
.adyen-checkout__issuer-button:active {
  color: var(--adyen-sdk-color-label-primary, #00112c);
}
.adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):focus {
  outline: none;
  box-shadow: inset 0 0 0 1px var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}
.adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 1px var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}
.adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):hover {
  outline: none;
  box-shadow: inset 0 0 0 1px var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}
.adyen-checkout__issuer-button--selected {
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  box-shadow: inset 0 0 0 1.5px var(--adyen-sdk-color-outline-primary-active, #00112c);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
  height: var(--adyen-sdk-spacer-110, 40px);
  transition: none;
}

.adyen-checkout__issuer-button-img {
  max-height: 26px;
  margin-right: var(--adyen-sdk-spacer-040, 8px);
}