.adyen-checkout__payment-method__header__title {
  max-height: 38px;
  display: flex;
  gap: var(--adyen-sdk-spacer-060, 12px);
  align-items: center;
  flex-shrink: 0;
  margin-right: var(--adyen-sdk-spacer-070, 16px);
  max-width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-size: 1em;
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
}
[dir=rtl] .adyen-checkout__payment-method__header__title {
  margin-right: var(--adyen-sdk-spacer-000, 0px);
  margin-left: var(--adyen-sdk-spacer-070, 16px);
}

/*  Payment Method Radio Button */
.adyen-checkout__payment-method__radio {
  background-color: var(--adyen-sdk-color-background-primary, #ffffff);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-secondary, #c9cdd3);
  border-radius: 50%;
  height: var(--adyen-sdk-spacer-070, 16px);
  width: var(--adyen-sdk-spacer-070, 16px);
  left: var(--adyen-sdk-spacer-070, 16px);
  transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
}
[dir=rtl] .adyen-checkout__payment-method__radio {
  right: var(--adyen-sdk-spacer-070, 16px);
  left: auto;
}

.adyen-checkout__payment-method__radio::after {
  content: "";
  display: block;
  position: relative;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  height: var(--adyen-sdk-spacer-040, 8px);
  width: var(--adyen-sdk-spacer-040, 8px);
  background-color: var(--adyen-sdk-color-background-primary, #ffffff);
  border-radius: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease-out;
}

.adyen-checkout__payment-method__radio--selected {
  background-color: var(--adyen-sdk-color-label-primary, #00112c);
  border: 0;
  transition: all 0.3s ease-out;
}
.adyen-checkout__payment-method__radio--selected:hover {
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-primary-hover, #c9cdd3);
}
.adyen-checkout__payment-method__radio--selected::after {
  transform: translateY(-50%) scale(1);
}