.adyen-checkout__fieldset--phone-input {
  direction: ltr;
}
.adyen-checkout__fieldset--phone-input .adyen-checkout__fieldset__fields {
  gap: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout__fieldset--phone-input .adyen-checkout__fieldset__fields > .adyen-checkout__field {
  margin-bottom: 0;
}

.adyen-checkout-field--phone-prefix {
  width: 144px;
}

.adyen-checkout-field--phone-number {
  flex: 1;
}