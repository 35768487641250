.adyen-checkout_upi-mode-selection-text {
  font-weight: var(--adyen-sdk-text-caption-font-weight, 400);
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  margin-top: var(--adyen-sdk-spacer-000, 0px);
  margin-bottom: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__segmented-control--upi-margin-bottom {
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout-upi-area-intent .adyen-checkout__button--pay {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout-upi-area-qr-code .adyen-checkout__button--pay {
  margin-top: var(--adyen-sdk-spacer-000, 0px);
}