.adyen-checkout__payment-method__name {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.adyen-checkout__payment-method__additional-info {
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
}
.adyen-checkout__payment-method__name_wrapper {
  display: flex;
  gap: var(--adyen-sdk-spacer-020, 4px);
  min-width: var(--adyen-sdk-spacer-120, 48px);
  align-items: flex-start;
  flex-direction: column;
  text-align: start;
}