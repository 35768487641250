.adyen-checkout__card__brands {
  display: flex;
  flex-basis: auto;
  flex-shrink: 1;
  flex-wrap: wrap;
  min-height: var(--adyen-sdk-spacer-090, 24px);
  margin-top: -12px;
  margin-bottom: var(--adyen-sdk-spacer-060, 12px);
  transition: all 0.3s ease-out;
}
.adyen-checkout__card__brands__brand-wrapper {
  box-shadow: var(--adyen-sdk-shadow-low, 0px 2px 4px rgba(0, 17, 44, 0.04), 0px 1px 2px rgba(0, 17, 44, 0.02));
  overflow: hidden;
  display: inline-block;
  height: var(--adyen-sdk-spacer-070, 16px);
  width: var(--adyen-sdk-spacer-090, 24px);
  position: relative;
  border-radius: var(--adyen-sdk-border-radius-s, 4px);
  margin: var(--adyen-sdk-spacer-020, 4px) var(--adyen-sdk-spacer-010, 2px);
}

.adyen-checkout__card__brands--hidden {
  opacity: 0;
  max-height: 0;
  min-height: 0;
  margin: -8px 0 8px;
}

.adyen-checkout__card__brands img {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  height: auto;
}