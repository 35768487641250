.adyen-checkout__payment-method {
  position: relative;
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-primary, #dbdee2);
  cursor: pointer;
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  width: 100%;
  transition: background 0.15s ease, border-color 0.15s ease, color 0.15s ease, box-shadow 0.15s ease, border-radius 0.15s ease;
}
.adyen-checkout__payment-method:focus {
  outline: 0;
}
.adyen-checkout__payment-method:hover:not(.adyen-checkout__payment-method--selected) {
  border-color: var(--adyen-sdk-color-outline-primary-hover, #c9cdd3);
}

.adyen-checkout__payment-method--loading {
  opacity: 0.2;
}

.adyen-checkout__payment-method--selected.adyen-checkout__payment-method--loading {
  opacity: 0.9;
}

.adyen-checkout__payment-method--confirming .adyen-checkout__payment-method__details__content {
  pointer-events: none;
  user-select: none;
}

.adyen-checkout__payment-method--disabling {
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
}

.adyen-checkout__payment-method--standalone .adyen-checkout__payment-method__radio {
  display: none;
}

.adyen-checkout__payment-method__header {
  align-items: center;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  font-size: var(--adyen-sdk-text-title-font-size, 16px);
  padding: var(--adyen-sdk-spacer-070, 16px);
  position: relative;
  transition: background 0.1s ease-out;
  width: 100%;
}
[dir=rtl] .adyen-checkout__payment-method__header {
  padding: calc(var(--adyen-sdk-spacer-070, 16px) - var(--adyen-sdk-spacer-020, 4px));
  padding-right: calc(var(--adyen-sdk-spacer-120, 48px) - var(--adyen-sdk-spacer-020, 4px));
}
.adyen-checkout__payment-method--standalone .adyen-checkout__payment-method__header {
  padding: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout__payment-method__surcharge {
  color: var(--adyen-sdk-color-outline-tertiary, #8d95a3);
  margin-left: var(--adyen-sdk-spacer-020, 4px);
}

.adyen-checkout__payment-method--selected {
  transition: opacity 0.3s ease-out;
  background: var(--adyen-sdk-color-background-secondary, #f7f7f8);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-secondary, #c9cdd3);
  cursor: default;
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__header {
  flex-wrap: wrap;
}

.adyen-checkout__payment-method__details {
  padding: var(--adyen-sdk-spacer-000, 0px) var(--adyen-sdk-spacer-070, 16px);
  position: relative;
}

.adyen-checkout__payment-method__details__content > *:last-child {
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout__payment-method__image__wrapper {
  position: relative;
  display: flex;
  width: var(--adyen-sdk-spacer-110, 40px);
  height: 26px;
  justify-content: center;
  align-items: center;
  border-radius: var(--adyen-sdk-border-radius-s, 4px);
  overflow: hidden;
  box-shadow: var(--adyen-sdk-shadow-low, 0px 2px 4px rgba(0, 17, 44, 0.04), 0px 1px 2px rgba(0, 17, 44, 0.02));
}

.adyen-checkout__payment-method__image {
  display: block;
  border-radius: var(--adyen-checkout-border-radius-s);
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;
}

.adyen-checkout__payment-method__brands {
  display: flex;
  gap: var(--adyen-sdk-spacer-020, 4px);
  flex-wrap: wrap;
  margin: var(--adyen-sdk-spacer-020, 4px) var(--adyen-sdk-spacer-000, 0px);
  height: var(--adyen-sdk-spacer-070, 16px);
  flex-basis: auto;
  flex-shrink: 1;
  text-align: right;
  overflow: hidden;
}
.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__brand-number {
  color: var(--adyen-sdk-color-outline-tertiary, #8d95a3);
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__brands {
  text-align: left;
  overflow: visible;
  height: auto;
}

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper {
  display: inline-block;
  height: var(--adyen-sdk-spacer-070, 16px);
  width: var(--adyen-sdk-spacer-090, 24px);
  transition: opacity 0.2s ease-out;
}

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:last-child {
  margin: var(--adyen-sdk-spacer-000, 0px);
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper {
  margin-bottom: var(--adyen-sdk-spacer-020, 4px);
}

/* todo: not working */
.adyen-checkout__payment-method__brands img {
  width: 100%;
  height: 100%;
  display: block;
}

.adyen-checkout__payment-method__image__wrapper--disabled {
  opacity: 0.25;
}

.adyen-checkout__payment-method:hover:not(.adyen-checkout__payment-method--selected) .adyen-checkout__payment-method__radio {
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-primary-hover, #c9cdd3);
  cursor: pointer;
}