.adyen-checkout-ctp__card-list-single-card {
  border-radius: var(--adyen-sdk-spacer-030, 6px);
  background-color: var(--adyen-sdk-color-background-secondary, #f7f7f8);
  height: var(--adyen-sdk-spacer-110, 40px);
  padding: var(--adyen-sdk-spacer-060, 12px);
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
}
.adyen-checkout-ctp__card-list-single-card-expired {
  text-decoration: line-through;
  color: var(--adyen-sdk-color-label-tertiary, #8d95a3);
}

.adyen-checkout-ctp__expired-label {
  color: var(--adyen-sdk-color-label-tertiary, #8d95a3);
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
  line-height: 17px;
  margin-left: auto;
}

.adyen-checkout-ctp__card-image {
  border-radius: 3px;
  margin-right: var(--adyen-sdk-spacer-040, 8px);
  height: 24px;
}