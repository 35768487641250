.adyen-checkout__giftcard-result__header {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  font-size: 1em;
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.adyen-checkout__giftcard-result__header__title {
  display: flex;
  align-items: center;
}

.adyen-checkout__giftcard-result__name {
  margin-left: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__giftcard-result__balance {
  padding: 0;
  list-style: none;
  margin: var(--adyen-sdk-spacer-070, 16px) 0 0;
}

.adyen-checkout__giftcard-result__balance__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--adyen-sdk-spacer-040, 8px);
}
.adyen-checkout__giftcard-result__balance__item .adyen-checkout__giftcard-result__balance__title--transactionLimit {
  color: var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}
.adyen-checkout__giftcard-result__balance__item:last-child {
  margin-bottom: 0;
}

.adyen-checkout__giftcard-result__balance__value--amount {
  font-weight: bold;
}

.adyen-checkout__giftcard-result__remaining-balance {
  font-size: 13px;
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  text-align: center;
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  margin: var(--adyen-sdk-spacer-040, 8px) auto 0;
}