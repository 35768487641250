.adyen-checkout__bankTransfer__introduction {
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  margin: 0 0 var(--adyen-sdk-spacer-070, 16px);
  padding: 0;
}

.adyen-checkout__bankTransfer__emailField {
  margin: 0 0 var(--adyen-sdk-spacer-070, 16px);
}