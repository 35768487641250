.adyen-checkout__status {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 350px;
  margin: 0;
  padding: var(--adyen-sdk-spacer-100, 32px);
  background-color: var(--adyen-sdk-color-background-primary, #ffffff);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-secondary, #c9cdd3);
  font-size: var(--adyen-sdk-text-title-font-size, 16px);
  color: var(--adyen-sdk-color-label-primary, #00112c);
}
.adyen-checkout__status__icon {
  margin-bottom: var(--adyen-sdk-spacer-090, 24px);
}
.adyen-checkout__status .adyen-checkout__spinner__wrapper {
  max-height: 88px;
}