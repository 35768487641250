.adyen-checkout__field {
  display: block;
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
  width: 100%;
}
.adyen-checkout__field--no-borders .adyen-checkout__input-wrapper {
  box-shadow: none;
  border: none;
  background-color: transparent;
}
.adyen-checkout__field--no-borders .adyen-checkout__input-wrapper:active, .adyen-checkout__field--no-borders .adyen-checkout__input-wrapper:active:hover, .adyen-checkout__field--no-borders .adyen-checkout__input-wrapper:focus-within, .adyen-checkout__field--no-borders .adyen-checkout__input-wrapper:focus-within:hover {
  box-shadow: none;
  border: none;
}
.adyen-checkout__field--no-borders .adyen-checkout__input-wrapper:hover {
  box-shadow: none;
  border: none;
}

.adyen-checkout__input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-primary, #dbdee2);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
}
.adyen-checkout__input-wrapper div,
.adyen-checkout__input-wrapper input,
.adyen-checkout__input-wrapper span {
  border-radius: inherit;
}
.adyen-checkout__input-wrapper div,
.adyen-checkout__input-wrapper input,
.adyen-checkout__input-wrapper button {
  border: none;
}
.adyen-checkout__input-wrapper:active, .adyen-checkout__input-wrapper:active:hover, .adyen-checkout__input-wrapper:focus-within, .adyen-checkout__input-wrapper:focus-within:hover {
  box-shadow: 0 0 0 0.5px var(--adyen-sdk-color-outline-primary-active, #00112c);
  border-color: var(--adyen-sdk-color-outline-primary-active, #00112c);
}
.adyen-checkout__input-wrapper:hover {
  border-color: var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}

.adyen-checkout__field--inactive > .adyen-checkout__input-wrapper {
  background: var(--adyen-sdk-color-background-disabled, #eeeff1);
  border-color: transparent;
}

.adyen-checkout__field--error > :not(.adyen-checkout__label--focused) + .adyen-checkout__input-wrapper:not(:focus-within, :active) {
  border-color: var(--adyen-sdk-color-outline-critical, #e22d2d);
}

.adyen-checkout__label {
  display: block;
  position: relative;
}

.adyen-checkout__label--focused + .adyen-checkout__input-wrapper {
  box-shadow: 0 0 0 0.5px var(--adyen-sdk-color-outline-primary-active, #00112c);
  border-color: var(--adyen-sdk-color-outline-primary-active, #00112c);
}

.adyen-checkout__label--disabled + .adyen-checkout__input-wrapper {
  background: var(--adyen-sdk-color-background-disabled, #eeeff1);
  border-color: transparent;
}

.adyen-checkout__helper-text {
  display: block;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  margin-bottom: var(--adyen-sdk-spacer-020, 4px);
}

.adyen-checkout__label-adornment--end {
  position: absolute;
  right: 0;
  top: 0;
}

.adyen-checkout__label__text {
  display: block;
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  margin-bottom: var(--adyen-sdk-spacer-020, 4px);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
  transition: color 0.1s ease-out;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.adyen-checkout__label__text--error {
  color: var(--adyen-sdk-color-label-critical, #e22d2d);
}

.adyen-checkout__label--focused .adyen-checkout__label__text {
  color: var(--adyen-sdk-color-label-primary, #00112c);
}

.adyen-checkout-contextual-text--error, .adyen-checkout-contextual-text {
  display: flex;
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  margin-top: var(--adyen-sdk-spacer-020, 4px);
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  align-items: center;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  opacity: 1;
  transition: margin 200ms ease-out, opacity 200ms ease-out;
}

.adyen-checkout-contextual-text--error {
  color: var(--adyen-sdk-color-label-critical, #e22d2d);
}

.adyen-checkout-contextual-text--hidden {
  height: 0;
  margin: 0;
  opacity: 0;
}