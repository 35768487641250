.adyen-checkout__loading-input__form {
  transition: opacity 0.25s ease-out;
}

.adyen-checkout__pm__holderName {
  margin-bottom: 0;
}
.adyen-checkout__pm__holderName .adyen-checkout__input {
  max-height: 100px;
}

.adyen-checkout__fieldset__title + .adyen-checkout__ach-sf__form {
  margin-top: 0;
}

.adyen-checkout__ach-sf__form {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout__ach-input .adyen-checkout__fieldset--address {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
}

.sf-input__wrapper {
  position: relative;
}