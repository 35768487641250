.adyen-checkout-ctp__section-logout-button {
  font-size: 13px;
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  margin-left: auto;
  cursor: pointer;
  text-decoration: underline;
}

.adyen-checkout-ctp__section-logout-button--disabled {
  pointer-events: none;
  color: var(--adyen-sdk-color-label-disabled, #8d95a3);
}