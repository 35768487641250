.adyen-checkout-ctp__section {
  position: relative;
  background-color: white;
  box-shadow: 0 6px 12px rgba(0, 17, 44, 0.08), 0 2px 4px rgba(0, 17, 44, 0.04);
  border-radius: var(--adyen-sdk-spacer-060, 12px);
  padding: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout-ctp__section-brand {
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 16px;
}
.adyen-checkout-ctp__section--standalone {
  all: unset;
}
.adyen-checkout-ctp__section .adyen-checkout__fieldset {
  margin-bottom: var(--adyen-sdk-spacer-090, 24px);
}

.adyen-checkout-ctp__section-header {
  display: flex;
  align-items: center;
}
.adyen-checkout-ctp__section-header-title {
  font-size: 17px;
  font-weight: var(--adyen-sdk-text-title-font-weight, 600);
  line-height: 22px;
  padding: 0;
  margin: 0;
  width: auto;
}
@media screen and (max-width: 400px) {
  .adyen-checkout-ctp__section-header-title {
    font-size: 15px;
  }
}
.adyen-checkout-ctp__section-header-adornment {
  margin-left: 5px;
}

.adyen-checkout-ctp__section-text {
  font-size: 13px;
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  color: var(--adyen-sdk-color-label-tertiary, #8d95a3);
  margin: 0 0 var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout-ctp__separator {
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-size: 13px;
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
}