.adyen-checkout__radio_group + .adyen-checkout-input__inline-validation {
  display: none;
}

.adyen-checkout__radio_group__input {
  opacity: 0;
  position: absolute;
}

.adyen-checkout__radio_group__label {
  margin: 0;
  padding-bottom: 0;
  padding-left: var(--adyen-sdk-spacer-090, 24px);
  position: relative;
  display: block;
  color: inherit;
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  font-weight: normal;
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
  overflow: visible;
}

.adyen-checkout__label--focused .adyen-checkout__radio_group__label {
  color: inherit;
}

.adyen-checkout__radio_group__label::before {
  content: "";
  position: absolute;
  background-color: var(--adyen-sdk-color-background-primary, #ffffff);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-tertiary, #8d95a3);
  border-radius: 50%;
  height: var(--adyen-sdk-spacer-070, 16px);
  width: var(--adyen-sdk-spacer-070, 16px);
  left: 0;
  top: var(--adyen-sdk-spacer-010, 2px);
  transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
}

.adyen-checkout__radio_group__label:hover::before {
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-primary-hover, #c9cdd3);
  cursor: pointer;
}

.adyen-checkout__radio_group__label::after {
  content: "";
  display: block;
  position: absolute;
  margin: 0 auto;
  left: 5px;
  top: 7px;
  height: var(--adyen-sdk-spacer-030, 6px);
  width: var(--adyen-sdk-spacer-030, 6px);
  background-color: var(--adyen-sdk-color-background-primary, #ffffff);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.2s ease-out;
  box-shadow: 0 1px 1px rgba(0, 15, 45, 0.25);
}

.adyen-checkout__radio_group__label:hover {
  border-color: var(--adyen-sdk-color-label-primary, #00112c);
  cursor: pointer;
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label::before,
.adyen-checkout__radio_group__label--selected {
  background-color: var(--adyen-sdk-color-label-primary, #00112c);
  border: 0;
  transition: all 0.2s ease-out;
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label::after {
  transform: scale(1);
}

.adyen-checkout__radio_group__input:focus + .adyen-checkout__radio_group__label::before {
  border-color: var(--adyen-sdk-color-label-primary, #00112c);
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label:hover::before,
.adyen-checkout__radio_group__input:checked:focus + .adyen-checkout__radio_group__label::before,
.adyen-checkout__radio_group__input:checked:active + .adyen-checkout__radio_group__label::before {
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}

.adyen-checkout__radio_group__label.adyen-checkout__radio_group__label--invalid::before {
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-critical, #e22d2d);
}

.adyen-checkout__radio_group--button {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: var(--adyen-sdk-spacer-070, 16px);
  align-self: stretch;
  width: 100%;
}
.adyen-checkout__radio_group--button .adyen-checkout__radio_group__input-wrapper {
  display: flex;
  flex: 1 0 0;
}
.adyen-checkout__radio_group--button .adyen-checkout__radio_group__label::after {
  position: absolute;
  top: calc(50% - var(--adyen-sdk-spacer-030, 6px) / 2);
  left: calc(var(--adyen-sdk-spacer-060, 12px) + 5px);
}
.adyen-checkout__radio_group--button .adyen-checkout__radio_group__label::before {
  position: absolute;
  top: calc(50% - var(--adyen-sdk-spacer-070, 16px) / 2);
  left: calc(var(--adyen-sdk-spacer-060, 12px));
}
.adyen-checkout__radio_group--button .adyen-checkout__radio_group__label {
  padding: var(--adyen-sdk-spacer-060, 12px);
  padding-left: calc(var(--adyen-sdk-spacer-060, 12px) + var(--adyen-sdk-spacer-090, 24px));
  width: 100%;
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-primary, #dbdee2);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  background-color: var(--adyen-sdk-color-background-primary, #ffffff);
}
.adyen-checkout__radio_group--button .adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label {
  border-color: var(--adyen-sdk-color-outline-primary-active, #00112c);
}
.adyen-checkout__radio_group--button .adyen-checkout__radio_group__input + .adyen-checkout__radio_group__label:hover {
  border-color: var(--adyen-sdk-color-outline-primary-active, #00112c);
}
.adyen-checkout__radio_group--button .adyen-checkout__radio_group__input:checked:focus + .adyen-checkout__radio_group__label,
.adyen-checkout__radio_group--button .adyen-checkout__radio_group__input:checked:active + .adyen-checkout__radio_group__label {
  box-shadow: 0 0 0 0.5px var(--adyen-sdk-color-outline-primary-active, #00112c);
  border-color: var(--adyen-sdk-color-outline-primary-active, #00112c);
}