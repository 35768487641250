.adyen-checkout__fieldset {
  display: block;
  padding-bottom: var(--adyen-sdk-spacer-040, 8px);
  width: 100%;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin: 0;
  border: none;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.adyen-checkout__fieldset:last-of-type {
  padding-bottom: 0;
}

.adyen-checkout__fieldset + .adyen-checkout__fieldset {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout__fieldset__title {
  font-size: var(--adyen-sdk-text-subtitle-font-size, 16px);
  font-weight: var(--adyen-sdk-text-subtitle-stronger-font-weight, 600);
  line-height: var(--adyen-sdk-text-subtitle-line-height, 26px);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-weight: var(--adyen-sdk-text-subtitle-font-weight, 500);
  display: block;
  margin: 0;
  padding: 0 0 var(--adyen-sdk-spacer-060, 12px);
}

.adyen-checkout__fieldset__fields,
.adyen-checkout__field-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 480px) {
  .adyen-checkout__field-group:last-of-type .adyen-checkout__field {
    margin-bottom: 0;
  }
}
.adyen-checkout__field-group:last-of-type .adyen-checkout__field:last-of-type {
  margin-bottom: 0;
}

:not(.adyen-checkout__field-group) > .adyen-checkout__field:last-of-type {
  margin-bottom: 0;
}

.adyen-checkout__fieldset--readonly .adyen-checkout__fieldset__fields {
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  margin: 0;
}