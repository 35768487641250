.adyen-checkout__filter-input:-webkit-autofill,
.adyen-checkout__filter-input:-webkit-autofill:active,
.adyen-checkout__filter-input:-webkit-autofill:hover,
.adyen-checkout__filter-input:-webkit-autofill:focus,
.adyen-checkout__filter-input:autofill,
.adyen-checkout__input:-webkit-autofill,
.adyen-checkout__input:-webkit-autofill:active,
.adyen-checkout__input:-webkit-autofill:hover,
.adyen-checkout__input:-webkit-autofill:focus,
.adyen-checkout__input:autofill {
  color: var(--adyen-sdk-color-label-primary, #00112c) !important;
  -webkit-text-fill-color: var(--adyen-sdk-color-label-primary, #00112c) !important;
  box-shadow: 0 0 0 1000px var(--adyen-sdk-color-background-primary, #ffffff) inset !important;
  background-clip: text !important;
  background-color: var(--adyen-sdk-color-label-primary, #00112c) !important;
  transition: none !important;
}

.adyen-checkout__input,
.adyen-checkout__filter-input {
  color: var(--adyen-sdk-color-label-primary, #00112c) !important;
  background-clip: text !important;
}

.adyen-checkout__field-wrapper {
  display: flex;
  width: 100%;
}

@media (min-width: 480px) {
  .adyen-checkout__field--20 {
    width: 20%;
  }
}

@media (min-width: 480px) {
  .adyen-checkout__field--30 {
    width: 30%;
  }
}

@media (min-width: 480px) {
  .adyen-checkout__field--40 {
    width: 40%;
  }
}

@media (min-width: 480px) {
  .adyen-checkout__field--50 {
    width: 50%;
  }
}

@media (min-width: 480px) {
  .adyen-checkout__field--60 {
    width: 60%;
  }
}

@media (min-width: 480px) {
  .adyen-checkout__field--70 {
    width: 70%;
  }
}

@media (min-width: 480px) {
  .adyen-checkout__field--80 {
    width: 80%;
  }
}

@media (min-width: 480px) {
  .adyen-checkout__field--col-70 {
    width: calc(70% - var(--adyen-sdk-spacer-040, 8px));
  }
}

@media (min-width: 480px) {
  .adyen-checkout__field--col-30 {
    width: calc(30% - var(--adyen-sdk-spacer-040, 8px));
  }
}

@media (min-width: 480px) {
  .adyen-checkout__field--col-50 {
    width: calc(50% - var(--adyen-sdk-spacer-040, 8px));
  }
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
  margin-right: var(--adyen-sdk-spacer-040, 8px);
}
[dir=rtl] .adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
  margin-right: 0;
  margin-left: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
  margin-left: var(--adyen-sdk-spacer-040, 8px);
}
[dir=rtl] .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
  margin-left: 0;
  margin-right: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__field-wrapper:last-of-type > .adyen-checkout__field {
  margin-bottom: 0;
}

.adyen-checkout__input {
  width: 100%;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-size: var(--adyen-sdk-text-subtitle-font-size, 16px);
  font-family: inherit;
  display: block;
  height: var(--adyen-sdk-spacer-110, 40px);
  padding-left: var(--adyen-sdk-spacer-060, 12px);
  background: inherit;
  outline: none;
  transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
}
.adyen-checkout__input:required {
  box-shadow: none;
}
.adyen-checkout__input:disabled, .adyen-checkout__input:read-only {
  color: var(--adyen-sdk-color-label-secondary, #5c687c) !important;
  cursor: default;
}

span.adyen-checkout__input {
  overflow: hidden;
}

.adyen-checkout-input__inline-validation {
  width: var(--adyen-sdk-spacer-070, 16px);
  height: var(--adyen-sdk-spacer-070, 16px);
  margin-right: var(--adyen-sdk-spacer-060, 12px);
}
[dir=rtl] .adyen-checkout-input__inline-validation {
  margin-left: var(--adyen-sdk-spacer-060, 12px);
}

.adyen-checkout-input__inline-validation--valid {
  color: var(--adyen-sdk-color-label-success, #07893c);
}

.adyen-checkout-input__inline-validation--invalid {
  color: var(--adyen-sdk-color-label-critical, #e22d2d);
}

.adyen-checkout__input--date {
  padding: 0.5em;
}