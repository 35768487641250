.adyen-checkout-trustly {
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout-trustly__descriptor {
  margin: var(--adyen-sdk-spacer-000, 0px) var(--adyen-sdk-spacer-000, 0px) var(--adyen-sdk-spacer-020, 4px);
  font-size: var(--adyen-sdk-text-subtitle-font-size, 16px);
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
}
.adyen-checkout-trustly__description-list {
  list-style-type: disc;
  margin: 0;
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  line-height: 1.5;
  padding-left: var(--adyen-sdk-spacer-080, 20px);
}