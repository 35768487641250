.adyen-checkout__payment-method__disable-confirmation {
  background: var(--adyen-sdk-color-background-critical-strong, #e22d2d);
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  color: var(--adyen-sdk-color-label-on-color, #ffffff);
  border-right: 1px solid #e12424;
  border-left: 1px solid #e12424;
  overflow: hidden;
  opacity: 0;
  margin: 0 -17px;
  max-height: 0;
  transition: opacity 0.15s ease-out, max-height 0.15s linear, margin-bottom 0.1s linear;
}
.adyen-checkout__payment-method__disable-confirmation.adyen-checkout__payment-method__disable-confirmation--open {
  max-height: 62px;
  opacity: 1;
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout__payment-method__disable-confirmation__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--adyen-sdk-spacer-040, 8px) var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout__payment-method__disable-confirmation__buttons {
  display: flex;
}

.adyen-checkout__payment-method__disable-confirmation__button {
  background: var(--adyen-sdk-color-background-critical-strong, #e22d2d);
  border: var(--adyen-sdk-border-width-s, 1px) solid transparent;
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  color: var(--adyen-sdk-color-label-on-color, #ffffff);
  cursor: pointer;
  display: block;
  height: auto;
  margin: 0 0 0 var(--adyen-sdk-spacer-040, 8px);
  padding: var(--adyen-sdk-spacer-040, 8px);
  width: auto;
}
.adyen-checkout__payment-method__disable-confirmation__button:hover, .adyen-checkout__payment-method__disable-confirmation__button:hover:focus {
  box-shadow: none;
  background: #d81e1e;
}
.adyen-checkout__payment-method__disable-confirmation__button:active, .adyen-checkout__payment-method__disable-confirmation__button:hover:active {
  background: #c11b1b;
  box-shadow: none;
}

.adyen-checkout__payment-method__disable-confirmation__button--remove,
.adyen-checkout__payment-method__disable-confirmation__button--remove:disabled {
  border-color: var(--adyen-sdk-color-label-on-color, #ffffff);
}

.adyen-checkout__payment-method__disable-confirmation__button--cancel,
.adyen-checkout__payment-method__disable-confirmation__button--cancel:disabled {
  border-color: transparent;
}