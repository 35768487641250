.adyen-checkout__bacs--confirm {
  position: relative;
}
.adyen-checkout__bacs--confirm .adyen-checkout-input__inline-validation--valid {
  display: none;
}
.adyen-checkout__bacs .adyen-checkout__field--inactive {
  pointer-events: none;
}
.adyen-checkout__bacs .adyen-checkout__bacs--edit {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.adyen-checkout__bacs .adyen-checkout__bacs--edit-dropin {
  top: -50px;
}
.adyen-checkout__bacs .adyen-checkout__bacs--edit .adyen-checkout__bacs--edit-button {
  border: none;
  background: none;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  text-decoration: underline;
  text-align: end;
  cursor: pointer;
  padding: 0;
}