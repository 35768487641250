.adyen-checkout-disclaimer__label {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
  padding-left: 0;
  display: inline-block;
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  font-weight: normal;
}
[dir=rtl] .adyen-checkout-disclaimer__label {
  padding-right: 0;
}